/* Common, default styles for the notification box */

.s-alert-box,
.s-alert-box * {
    box-sizing: border-box;
}

.s-alert-box {
    position: fixed;
    background: rgba(42,45,50,0.85);
    padding: 22px;
    line-height: 1.4;
    z-index: 1000;
    pointer-events: none;
    color: rgba(250,251,255,0.95);
    font-size: 100%;
    font-family: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
    max-width: 300px;
    -webkit-transition: top .4s, bottom .4s;
    transition: top .4s, bottom .4s;
}

.s-alert-box.s-alert-show {
    pointer-events: auto;
}

.s-alert-box a {
    color: inherit;
    opacity: 0.7;
    font-weight: 700;
}

.s-alert-box a:hover,
.s-alert-box a:focus {
    opacity: 1;
}

.s-alert-box p {
    margin: 0;
}

.s-alert-box.s-alert-show,
.s-alert-box.s-alert-visible {
    pointer-events: auto;
}

.s-alert-close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 4px;
    top: 4px;
    overflow: hidden;
    text-indent: 100%;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.s-alert-close:hover,
.s-alert-close:focus {
    outline: none;
}

.s-alert-close::before,
.s-alert-close::after {
    content: '';
    position: absolute;
    width: 3px;
    height: 60%;
    top: 50%;
    left: 50%;
    background: #fff;
}

.s-alert-close:hover::before,
.s-alert-close:hover::after {
    background: #fff;
}

.s-alert-close::before {
    -webkit-transform: translate(-50%,-50%) rotate(45deg);
    transform: translate(-50%,-50%) rotate(45deg);
}

.s-alert-close::after {
    -webkit-transform: translate(-50%,-50%) rotate(-45deg);
    transform: translate(-50%,-50%) rotate(-45deg);
}

/* positions */

.s-alert-bottom-left {
    top: auto;
    right: auto;
    bottom: 30px;
    left: 30px;
}
.s-alert-top-left {
    top: 30px;
    right: auto;
    bottom: auto;
    left: 30px;
}
.s-alert-top-right {
    top: 30px;
    right: 30px;
    bottom: auto;
    left: auto;
}
.s-alert-bottom-right { /*default*/
    top: auto;
    right: 30px;
    bottom: 30px;
    left: auto;
}
.s-alert-bottom {
    width: 100%;
    max-width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
}
.s-alert-top {
    width: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
}

/* conditions */

.s-alert-info {
    background: #00A2D3;
    color: #fff;
}
.s-alert-success {
    background: #27AE60;
    color: #fff;
}
.s-alert-warning {
    background: #F1C40F;
    color: #fff;
}
.s-alert-error {
    background: #E74C3C;
    color: #fff;
}

[class^="s-alert-effect-"].s-alert-hide,
[class*=" s-alert-effect-"].s-alert-hide {
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

/* height measurement helper */
.s-alert-box-height {
    visibility: hidden;
    position: fixed;
}


/* Genie */

.s-alert-effect-genie .s-alert-close::after,
.s-alert-effect-genie .s-alert-close::before {
    background: #fff;
}

.s-alert-effect-genie .s-alert-close:hover::after,
.s-alert-effect-genie .s-alert-close:hover::before {
    background: #fff;
}

.s-alert-effect-genie.s-alert-show,
.s-alert-effect-genie.s-alert-hide {
    -webkit-animation-name: animGenie;
    animation-name: animGenie;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
}

@-webkit-keyframes animGenie {
    0% { opacity:0; -webkit-transform: translate3d(0,calc(200% + 30px),0) scale3d(0,1,1); -webkit-animation-timing-function: ease-in; }
    40% { opacity:0.5; -webkit-transform: translate3d(0,0,0) scale3d(0.02,1.1,1); -webkit-animation-timing-function: ease-out; }
    70% { opacity:0.6; -webkit-transform: translate3d(0,-40px,0) scale3d(0.8,1.1,1); }
    100% { opacity:1; -webkit-transform: translate3d(0,0,0) scale3d(1,1,1); }
}

@keyframes animGenie {
    0% { opacity:0; -webkit-transform: translate3d(0,calc(200% + 30px),0) scale3d(0,1,1); -webkit-animation-timing-function: ease-in; transform: translate3d(0,calc(200% + 30px),0) scale3d(0,1,1); animation-timing-function: ease-in; }
    40% { opacity:0.5; -webkit-transform: translate3d(0,0,0) scale3d(0.02,1.1,1); -webkit-animation-timing-function: ease-out; transform: translate3d(0,0,0) scale3d(0.02,1.1,1); animation-timing-function: ease-out; }
    70% { opacity:0.6; -webkit-transform: translate3d(0,-40px,0) scale3d(0.8,1.1,1); transform: translate3d(0,-40px,0) scale3d(0.8,1.1,1); }
    100% { opacity:1; -webkit-transform: translate3d(0,0,0) scale3d(1,1,1); transform: translate3d(0,0,0) scale3d(1,1,1); }
}
